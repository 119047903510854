import React, { useEffect, useRef } from "react";
import { StyledAlerta } from "../styles/Alerta";
import cross from "../assets/cross.svg";
import disk from "../assets/disk.svg";

import { useState } from "react";
import AlertCard from "../components/AlertCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Alerta({ dados, tagAlerta, setTagAlerta, tagCidade }) {
  const [alertOpen, setAlertOpen] = useState(tagAlerta ? true : false);
  const [suggestionName, setSuggestionName] = useState("");
  const [suggestionContestValue, setSuggestionContestValue] = useState("");
  const [suggestionLocationValue, setSuggestionLocationValue] = useState("");
  const [suggestionOfficeValue, setSuggestionOfficeValue] = useState(
    tagAlerta ? tagAlerta : ""
  );

  // ESTADOS
  const [pesquisaEstado, setPesquisaEstado] = useState("");
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);
  const [showList, setShowList] = useState(false);

  // CIDADES
  const [listaDeCidades, setListaDeCidades] = useState([]);
  const [listaDeCidadesName, setListaDeCidadesName] = useState(null);

  const [pesquisaCidade, setPesquisaCidade] = useState("");
  const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
  const [showListCidade, setShowListCidade] = useState(false);

  // CARGOS
  const [listaDeCargos, setListaDeCargos] = useState([]);
  const [listaDeCargosName, setListaDeCargosName] = useState(null);
  const [showListCargos, setShowListCargos] = useState(false);

  // CONCURSOS
  const [listaDeConcursos, setListaDeConcursos] = useState(null);
  const [listaDeConcursosName, setListaDeConcursosName] = useState(null);
  const [showListConcursos, setShowListConcursos] = useState(false);

  const [recarregar, setRecarregar] = useState(false);
  const [dadosSecond, setDadosSecond] = useState(dados);

  const [popUp, setPopUp] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const URL = process.env.REACT_APP_API_URL;
  const Token = process.env.REACT_APP_TOKEN;

  const refCargos = useRef(null);
  const refConcursos = useRef(null);
  const refEstados = useRef(null);
  const refCidades = useRef(null);

  const [filteredContest, setFilteredContest] = useState([]);
  const [filteredCity, setFilteredCity] = useState([]);

  const cargosOutside = (event) => {
    if (refCargos.current && !refCargos.current.contains(event.target)) {
      setShowListCargos(false);
    }
  };
  const concursosOutside = (event) => {
    if (refConcursos.current && !refConcursos.current.contains(event.target)) {
      setShowListConcursos(false);
    }
  };

  const estadosOutside = (event) => {
    if (refEstados.current && !refEstados.current.contains(event.target)) {
      setShowList(false);
    }
  };
  const cidadesOutside = (event) => {
    if (refCidades.current && !refCidades.current.contains(event.target)) {
      setShowListCidade(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", cargosOutside);
    return () => {
      document.removeEventListener("mousedown", cargosOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", concursosOutside);
    return () => {
      document.removeEventListener("mousedown", concursosOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", estadosOutside);
    return () => {
      document.removeEventListener("mousedown", estadosOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", cidadesOutside);
    return () => {
      document.removeEventListener("mousedown", cidadesOutside);
    };
  }, []);

  useEffect(() => {
    async function requisicaoParaPegarDados(email) {
      const response = await fetch(`${URL}/user/users/?emails=${email}`, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      });
      const data = await response.json();

      return data;
    }

    if (!user) {
      return;
    } else {
      requisicaoParaPegarDados(user.email).then((data) => {
        setDadosSecond(data.results[0]);
      });
    }
  }, [user, Token, URL, recarregar]);

  useEffect(() => {
    document.title = "Alertas - Alô Concursos";
    return () => {
      setTagAlerta && setTagAlerta(null);
    };
  }, []);

  useEffect(() => {
    async function requisicao(apiLink, type) {
      await fetch(apiLink, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (type === "officeTag") {
            setListaDeCargos(response);
          }
          if (type === "contestTag") {
            setListaDeConcursos(response);
          }
        });
    }

    requisicao(`${URL}/office/top-tags/`, "officeTag");
    requisicao(`${URL}/contest/top-tags/`, "contestTag");
  }, []);

  useEffect(() => {
    setListaDeCargosName(listaDeCargos.map((item) => item.name));
  }, [listaDeCargos]);

  useEffect(() => {
    if (listaDeConcursos) {
      setListaDeConcursosName(listaDeConcursos.map((item) => item.name));
    }
  }, [listaDeConcursos]);

  useEffect(() => {
    async function requisicao(apiLink, type) {
      await fetch(apiLink, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (type === "location") {
            setListaDeCidades(response.results);
          }
        });
    }

    let estadoGet = obterSigla(estadoSelecionado);

    requisicao(
      `${URL}/location/location/?limit=1000&state=${estadoGet}`,
      "location"
    );
  }, [estadoSelecionado, URL, Token]);

  useEffect(() => {
    setListaDeCidadesName(listaDeCidades.map((item) => item.name));
  }, [listaDeCidades]);

  const estadosBrasil = [
    "Acre",
    "Alagoas",
    "Amapá",
    "Amazonas",
    "Bahia",
    "Ceará",
    "Distrito Federal",
    "Espírito Santo",
    "Goiás",
    "Maranhão",
    "Mato Grosso",
    "Mato Grosso do Sul",
    "Minas Gerais",
    "Pará",
    "Paraíba",
    "Paraná",
    "Pernambuco",
    "Piauí",
    "Rio de Janeiro",
    "Rio Grande do Norte",
    "Rio Grande do Sul",
    "Rondônia",
    "Roraima",
    "Santa Catarina",
    "São Paulo",
    "Sergipe",
    "Tocantins",
  ];
  const siglasEstados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  function obterSigla(estado) {
    const index = estadosBrasil.indexOf(estado);

    if (index !== -1) {
      return siglasEstados[index];
    }
  }

  const handleStateClick = (estado) => {
    setEstadoSelecionado(estado);
    setPesquisaEstado(estado);
    setShowList(false);
  };
  const handleInputBlur = () => {
    // Delay para garantir que o clique no item da lista seja registrado antes de ocultar
    setTimeout(() => setShowList(false), 150);
  };

  const handleCityClick = (cidade) => {
    setCidadeSelecionada(cidade);
    setPesquisaCidade(cidade);
    setShowListCidade(false);
  };

  const handleCityInputBlur = () => {
    // Delay para garantir que o clique no item da lista seja registrado antes de ocultar
    setTimeout(() => setShowListCidade(false), 150);
  };

  async function criandoAlerta(e) {
    e.preventDefault();

    const data = {
      name: suggestionName && suggestionName,
      user_id: dadosSecond.id,
      contest_tag_name: suggestionContestValue ? suggestionContestValue : null,
      location_name: pesquisaCidade ? pesquisaCidade : null,
      office_tag_name: suggestionOfficeValue ? suggestionOfficeValue : null,
    };

    fetch(`${URL}/alerts/alerts/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          setRecarregar(!recarregar);
        }
        return res.json();
      })
      .catch((error) => console.error("Error:", error));

    setAlertOpen(false);
    setSuggestionName("");
    setSuggestionOfficeValue("");
    setSuggestionContestValue("");
    setPesquisaEstado("");
    setPesquisaCidade("");
    setSuggestionLocationValue("");
  }

  // filtros digitando nos inputs

  const filteredStates = estadosBrasil.filter((estado) =>
    estado.toLowerCase().includes(pesquisaEstado.toLowerCase())
  );

  const filteredOffices = listaDeCargosName
    ? listaDeCargosName.filter((cargo) =>
        cargo.toLowerCase().includes(suggestionOfficeValue.toLowerCase())
      )
    : null;

  useEffect(() => {
    if (listaDeCidadesName) {
      setFilteredCity(
        listaDeCidadesName.filter((cidade) =>
          cidade.toLowerCase().includes(pesquisaCidade.toLowerCase())
        )
      );
    }
  }, [pesquisaCidade, listaDeCidadesName]);

  useEffect(() => {
    if (listaDeConcursos) {
      if (listaDeConcursosName) {
        setFilteredContest(
          listaDeConcursosName.filter((concurso) =>
            concurso
              .toLowerCase()
              .includes(suggestionContestValue.toLowerCase())
          )
        );
      }
    }
  }, [suggestionContestValue, listaDeConcursosName, listaDeConcursos]);

  return (
    <StyledAlerta>
      <div className="flexAlerta">
        <div className="tittleAlerta">
          <h2>Alertas</h2>
          {false ? (
            <p>
              Crie alertas para os <span>cargos, concursos e localidades</span>{" "}
              que você procura para ser{" "}
              <span>
                notificado por e-mail assim que o concurso estiver na Alô
                Concursos.
              </span>
            </p>
          ) : (
            <p>
              Você precisa ser um assinante{" "}
              <Link to="/planos">Premium ou Premium +</Link> para poder usar o
              Sistema de Alertas.
            </p>
          )}
        </div>
        <div className="relativeBoxAlertaPopup">
          {/* substituir true por dadosSecond */}
          {/* {false ? ( */}
          <button onClick={() => setAlertOpen(true)} className="criarAlerta">
            Criar alerta
          </button>
          {/* ) : (
            <button className="criarAlerta">
              <Link to="/planos">Assinar</Link>
            </button>
          )} */}
        </div>

        <h3 className="alertasCriadas">Alertas criados</h3>
        {alertOpen && (
          <form onSubmit={criandoAlerta} className="formAlerta">
            <h3>Criar alerta</h3>
            {/* trocar false por dadosSecond?.subscription_active */}

            <p className="formAlertaText">
              Crie alertas para os <span>cargos, concursos e localidades</span>{" "}
              que você procura para ser{" "}
              <span>
                notificado por e-mail assim que o concurso estiver na Alô
                Concursos.
              </span>
            </p>

            <div className="boxLabelAlerta">
              <label htmlFor="name">Nome</label>
              <div className="boxLabelAlertaTextInput">
                <p>Adicionar nome do alerta</p>
                {dadosSecond?.subscription_active ? (
                  <input
                    value={suggestionName}
                    onChange={(e) => setSuggestionName(e.target.value)}
                    placeholder="Ex: Administração Municipal RJ"
                    type="text"
                    id="name"
                    autoComplete="off"
                  />
                ) : (
                  <div className="disbaleFlex">
                    <input
                      className="disabledButton"
                      placeholder="Ex: Administração Municipal RJ"
                      type="text"
                      id="name"
                      disabled
                    />
                    <svg
                      className="cadeadoDisable"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_4431_44218)">
                        <path
                          d="M12.6668 6.116V5.16667C12.6668 3.92899 12.1752 2.742 11.3 1.86684C10.4248 0.991665 9.23784 0.5 8.00016 0.5C6.76249 0.5 5.5755 0.991665 4.70033 1.86684C3.82516 2.742 3.3335 3.92899 3.3335 5.16667V6.116C2.73974 6.37514 2.23436 6.80168 1.87916 7.34347C1.52397 7.88527 1.33435 8.51882 1.3335 9.16667V13.1667C1.33455 14.0504 1.68608 14.8976 2.31098 15.5225C2.93587 16.1474 3.7831 16.4989 4.66683 16.5H11.3335C12.2172 16.4989 13.0645 16.1474 13.6894 15.5225C14.3142 14.8976 14.6658 14.0504 14.6668 13.1667V9.16667C14.666 8.51882 14.4764 7.88527 14.1212 7.34347C13.766 6.80168 13.2606 6.37514 12.6668 6.116ZM4.66683 5.16667C4.66683 4.28261 5.01802 3.43477 5.64314 2.80964C6.26826 2.18452 7.11611 1.83333 8.00016 1.83333C8.88422 1.83333 9.73207 2.18452 10.3572 2.80964C10.9823 3.43477 11.3335 4.28261 11.3335 5.16667V5.83333H4.66683V5.16667ZM13.3335 13.1667C13.3335 13.6971 13.1228 14.2058 12.7477 14.5809C12.3726 14.956 11.8639 15.1667 11.3335 15.1667H4.66683C4.1364 15.1667 3.62769 14.956 3.25262 14.5809C2.87754 14.2058 2.66683 13.6971 2.66683 13.1667V9.16667C2.66683 8.63623 2.87754 8.12753 3.25262 7.75245C3.62769 7.37738 4.1364 7.16667 4.66683 7.16667H11.3335C11.8639 7.16667 12.3726 7.37738 12.7477 7.75245C13.1228 8.12753 13.3335 8.63623 13.3335 9.16667V13.1667Z"
                          fill="#7E849E"
                        />
                        <path
                          d="M7.99967 9.83203C7.82286 9.83203 7.65329 9.90227 7.52827 10.0273C7.40325 10.1523 7.33301 10.3219 7.33301 10.4987V11.832C7.33301 12.0088 7.40325 12.1784 7.52827 12.3034C7.65329 12.4285 7.82286 12.4987 7.99967 12.4987C8.17649 12.4987 8.34605 12.4285 8.47108 12.3034C8.5961 12.1784 8.66634 12.0088 8.66634 11.832V10.4987C8.66634 10.3219 8.5961 10.1523 8.47108 10.0273C8.34605 9.90227 8.17649 9.83203 7.99967 9.83203Z"
                          fill="#7E849E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4431_44218">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}
              </div>
              <hr />
              <div className="boxLabelAlertaTextInput">
                <label htmlFor="cargos">Cargos</label>
                <p>Etiquetas de cargos</p>
                {dadosSecond?.subscription_active ? (
                  <div ref={refCargos}>
                    <input
                      value={suggestionOfficeValue}
                      onChange={(e) => setSuggestionOfficeValue(e.target.value)}
                      placeholder="Ex: Medicina"
                      type="text"
                      id="cargos"
                      onFocus={() => setShowListCargos(true)}
                      autoComplete="off"
                    />
                    {showListCargos && (
                      <ul
                        className="ulOption"
                        onClick={() => setShowListCargos(false)}
                      >
                        {filteredOffices &&
                          filteredOffices.map((cargo) => (
                            <li
                              key={cargo}
                              onClick={() => setSuggestionOfficeValue(cargo)}
                            >
                              {cargo}
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <div className="disbaleFlex">
                    <input
                      className="disabledButton"
                      placeholder="Ex: Medicina"
                      type="text"
                      id="cargos"
                      disabled
                    />
                    <svg
                      className="cadeadoDisable"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_4431_44218)">
                        <path
                          d="M12.6668 6.116V5.16667C12.6668 3.92899 12.1752 2.742 11.3 1.86684C10.4248 0.991665 9.23784 0.5 8.00016 0.5C6.76249 0.5 5.5755 0.991665 4.70033 1.86684C3.82516 2.742 3.3335 3.92899 3.3335 5.16667V6.116C2.73974 6.37514 2.23436 6.80168 1.87916 7.34347C1.52397 7.88527 1.33435 8.51882 1.3335 9.16667V13.1667C1.33455 14.0504 1.68608 14.8976 2.31098 15.5225C2.93587 16.1474 3.7831 16.4989 4.66683 16.5H11.3335C12.2172 16.4989 13.0645 16.1474 13.6894 15.5225C14.3142 14.8976 14.6658 14.0504 14.6668 13.1667V9.16667C14.666 8.51882 14.4764 7.88527 14.1212 7.34347C13.766 6.80168 13.2606 6.37514 12.6668 6.116ZM4.66683 5.16667C4.66683 4.28261 5.01802 3.43477 5.64314 2.80964C6.26826 2.18452 7.11611 1.83333 8.00016 1.83333C8.88422 1.83333 9.73207 2.18452 10.3572 2.80964C10.9823 3.43477 11.3335 4.28261 11.3335 5.16667V5.83333H4.66683V5.16667ZM13.3335 13.1667C13.3335 13.6971 13.1228 14.2058 12.7477 14.5809C12.3726 14.956 11.8639 15.1667 11.3335 15.1667H4.66683C4.1364 15.1667 3.62769 14.956 3.25262 14.5809C2.87754 14.2058 2.66683 13.6971 2.66683 13.1667V9.16667C2.66683 8.63623 2.87754 8.12753 3.25262 7.75245C3.62769 7.37738 4.1364 7.16667 4.66683 7.16667H11.3335C11.8639 7.16667 12.3726 7.37738 12.7477 7.75245C13.1228 8.12753 13.3335 8.63623 13.3335 9.16667V13.1667Z"
                          fill="#7E849E"
                        />
                        <path
                          d="M7.99967 9.83203C7.82286 9.83203 7.65329 9.90227 7.52827 10.0273C7.40325 10.1523 7.33301 10.3219 7.33301 10.4987V11.832C7.33301 12.0088 7.40325 12.1784 7.52827 12.3034C7.65329 12.4285 7.82286 12.4987 7.99967 12.4987C8.17649 12.4987 8.34605 12.4285 8.47108 12.3034C8.5961 12.1784 8.66634 12.0088 8.66634 11.832V10.4987C8.66634 10.3219 8.5961 10.1523 8.47108 10.0273C8.34605 9.90227 8.17649 9.83203 7.99967 9.83203Z"
                          fill="#7E849E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4431_44218">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}
              </div>

              <hr />
              <label htmlFor="concurso">Concursos</label>
              <div className="boxLabelAlertaTextInput">
                <p>Etiquetas de concursos</p>
                {dadosSecond?.subscription_active ? (
                  <div ref={refConcursos}>
                    <input
                      onChange={(e) =>
                        setSuggestionContestValue(e.target.value)
                      }
                      value={suggestionContestValue}
                      placeholder="Ex: PCMG"
                      type="text"
                      id="concurso"
                      onFocus={() => setShowListConcursos(true)}
                      autoComplete="off"
                    />
                    {showListConcursos && (
                      <ul
                        className="ulOption"
                        onClick={() => setShowListConcursos(false)}
                      >
                        {filteredContest &&
                          filteredContest.map((concurso) => (
                            <li
                              key={concurso}
                              onClick={() =>
                                setSuggestionContestValue(concurso)
                              }
                            >
                              {concurso}
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <div className="disbaleFlex">
                    <input
                      className="disabledButton"
                      placeholder="Ex: PCMG"
                      type="text"
                      id="concurso"
                      disabled
                    />
                    <svg
                      className="cadeadoDisable"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_4431_44218)">
                        <path
                          d="M12.6668 6.116V5.16667C12.6668 3.92899 12.1752 2.742 11.3 1.86684C10.4248 0.991665 9.23784 0.5 8.00016 0.5C6.76249 0.5 5.5755 0.991665 4.70033 1.86684C3.82516 2.742 3.3335 3.92899 3.3335 5.16667V6.116C2.73974 6.37514 2.23436 6.80168 1.87916 7.34347C1.52397 7.88527 1.33435 8.51882 1.3335 9.16667V13.1667C1.33455 14.0504 1.68608 14.8976 2.31098 15.5225C2.93587 16.1474 3.7831 16.4989 4.66683 16.5H11.3335C12.2172 16.4989 13.0645 16.1474 13.6894 15.5225C14.3142 14.8976 14.6658 14.0504 14.6668 13.1667V9.16667C14.666 8.51882 14.4764 7.88527 14.1212 7.34347C13.766 6.80168 13.2606 6.37514 12.6668 6.116ZM4.66683 5.16667C4.66683 4.28261 5.01802 3.43477 5.64314 2.80964C6.26826 2.18452 7.11611 1.83333 8.00016 1.83333C8.88422 1.83333 9.73207 2.18452 10.3572 2.80964C10.9823 3.43477 11.3335 4.28261 11.3335 5.16667V5.83333H4.66683V5.16667ZM13.3335 13.1667C13.3335 13.6971 13.1228 14.2058 12.7477 14.5809C12.3726 14.956 11.8639 15.1667 11.3335 15.1667H4.66683C4.1364 15.1667 3.62769 14.956 3.25262 14.5809C2.87754 14.2058 2.66683 13.6971 2.66683 13.1667V9.16667C2.66683 8.63623 2.87754 8.12753 3.25262 7.75245C3.62769 7.37738 4.1364 7.16667 4.66683 7.16667H11.3335C11.8639 7.16667 12.3726 7.37738 12.7477 7.75245C13.1228 8.12753 13.3335 8.63623 13.3335 9.16667V13.1667Z"
                          fill="#7E849E"
                        />
                        <path
                          d="M7.99967 9.83203C7.82286 9.83203 7.65329 9.90227 7.52827 10.0273C7.40325 10.1523 7.33301 10.3219 7.33301 10.4987V11.832C7.33301 12.0088 7.40325 12.1784 7.52827 12.3034C7.65329 12.4285 7.82286 12.4987 7.99967 12.4987C8.17649 12.4987 8.34605 12.4285 8.47108 12.3034C8.5961 12.1784 8.66634 12.0088 8.66634 11.832V10.4987C8.66634 10.3219 8.5961 10.1523 8.47108 10.0273C8.34605 9.90227 8.17649 9.83203 7.99967 9.83203Z"
                          fill="#7E849E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4431_44218">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}
              </div>
              <hr />
              <label htmlFor="cidade">Estados</label>
              <div className="boxLabelAlertaTextInput">
                <p>Adicionar estado</p>
                {dadosSecond?.subscription_active ? (
                  <div ref={refEstados}>
                    <input
                      type="text"
                      id="search"
                      value={pesquisaEstado}
                      onChange={(e) => setPesquisaEstado(e.target.value)}
                      onFocus={() => setShowList(true)}
                      onBlur={handleInputBlur}
                      placeholder="Digite o nome do estado"
                      autoComplete="off"
                    />
                    {showList && (
                      <ul className="ulOption">
                        {filteredStates.map((estado) => (
                          <li
                            key={estado}
                            onClick={() => handleStateClick(estado)}
                          >
                            {estado}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <div className="disbaleFlex">
                    <input
                      className="disabledButton"
                      placeholder="Digite o nome do estado"
                      type="text"
                      id="concurso"
                      disabled
                    />
                    <svg
                      className="cadeadoDisable"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_4431_44218)">
                        <path
                          d="M12.6668 6.116V5.16667C12.6668 3.92899 12.1752 2.742 11.3 1.86684C10.4248 0.991665 9.23784 0.5 8.00016 0.5C6.76249 0.5 5.5755 0.991665 4.70033 1.86684C3.82516 2.742 3.3335 3.92899 3.3335 5.16667V6.116C2.73974 6.37514 2.23436 6.80168 1.87916 7.34347C1.52397 7.88527 1.33435 8.51882 1.3335 9.16667V13.1667C1.33455 14.0504 1.68608 14.8976 2.31098 15.5225C2.93587 16.1474 3.7831 16.4989 4.66683 16.5H11.3335C12.2172 16.4989 13.0645 16.1474 13.6894 15.5225C14.3142 14.8976 14.6658 14.0504 14.6668 13.1667V9.16667C14.666 8.51882 14.4764 7.88527 14.1212 7.34347C13.766 6.80168 13.2606 6.37514 12.6668 6.116ZM4.66683 5.16667C4.66683 4.28261 5.01802 3.43477 5.64314 2.80964C6.26826 2.18452 7.11611 1.83333 8.00016 1.83333C8.88422 1.83333 9.73207 2.18452 10.3572 2.80964C10.9823 3.43477 11.3335 4.28261 11.3335 5.16667V5.83333H4.66683V5.16667ZM13.3335 13.1667C13.3335 13.6971 13.1228 14.2058 12.7477 14.5809C12.3726 14.956 11.8639 15.1667 11.3335 15.1667H4.66683C4.1364 15.1667 3.62769 14.956 3.25262 14.5809C2.87754 14.2058 2.66683 13.6971 2.66683 13.1667V9.16667C2.66683 8.63623 2.87754 8.12753 3.25262 7.75245C3.62769 7.37738 4.1364 7.16667 4.66683 7.16667H11.3335C11.8639 7.16667 12.3726 7.37738 12.7477 7.75245C13.1228 8.12753 13.3335 8.63623 13.3335 9.16667V13.1667Z"
                          fill="#7E849E"
                        />
                        <path
                          d="M7.99967 9.83203C7.82286 9.83203 7.65329 9.90227 7.52827 10.0273C7.40325 10.1523 7.33301 10.3219 7.33301 10.4987V11.832C7.33301 12.0088 7.40325 12.1784 7.52827 12.3034C7.65329 12.4285 7.82286 12.4987 7.99967 12.4987C8.17649 12.4987 8.34605 12.4285 8.47108 12.3034C8.5961 12.1784 8.66634 12.0088 8.66634 11.832V10.4987C8.66634 10.3219 8.5961 10.1523 8.47108 10.0273C8.34605 9.90227 8.17649 9.83203 7.99967 9.83203Z"
                          fill="#7E849E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4431_44218">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}
              </div>
              <hr />
              <label htmlFor="cidade">Cidades</label>
              <div className="boxLabelAlertaTextInput">
                <p>Adicionar cidade</p>
                {dadosSecond?.subscription_active ? (
                  <div ref={refCidades}>
                    <input
                      value={pesquisaCidade}
                      onChange={(e) => setPesquisaCidade(e.target.value)}
                      onFocus={() => setShowListCidade(true)}
                      onBlur={handleCityInputBlur}
                      placeholder={
                        pesquisaEstado
                          ? `Ex: Niterói - RJ`
                          : `Por favor preencha o estado acima`
                      }
                      type="text"
                      id="cidade"
                      disabled={!pesquisaEstado}
                      autoComplete="off"
                    />
                    {showListCidade && (
                      <ul className="ulOption">
                        {filteredCity.map((cidade, index) => (
                          <li
                            key={index}
                            onClick={() => handleCityClick(cidade)}
                          >
                            {cidade}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <div className="disbaleFlex">
                    <input
                      className="disabledButton"
                      placeholder="Ex: Niterói - RJ"
                      type="text"
                      id="cidade"
                      disabled
                    />
                    <svg
                      className="cadeadoDisable"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_4431_44218)">
                        <path
                          d="M12.6668 6.116V5.16667C12.6668 3.92899 12.1752 2.742 11.3 1.86684C10.4248 0.991665 9.23784 0.5 8.00016 0.5C6.76249 0.5 5.5755 0.991665 4.70033 1.86684C3.82516 2.742 3.3335 3.92899 3.3335 5.16667V6.116C2.73974 6.37514 2.23436 6.80168 1.87916 7.34347C1.52397 7.88527 1.33435 8.51882 1.3335 9.16667V13.1667C1.33455 14.0504 1.68608 14.8976 2.31098 15.5225C2.93587 16.1474 3.7831 16.4989 4.66683 16.5H11.3335C12.2172 16.4989 13.0645 16.1474 13.6894 15.5225C14.3142 14.8976 14.6658 14.0504 14.6668 13.1667V9.16667C14.666 8.51882 14.4764 7.88527 14.1212 7.34347C13.766 6.80168 13.2606 6.37514 12.6668 6.116ZM4.66683 5.16667C4.66683 4.28261 5.01802 3.43477 5.64314 2.80964C6.26826 2.18452 7.11611 1.83333 8.00016 1.83333C8.88422 1.83333 9.73207 2.18452 10.3572 2.80964C10.9823 3.43477 11.3335 4.28261 11.3335 5.16667V5.83333H4.66683V5.16667ZM13.3335 13.1667C13.3335 13.6971 13.1228 14.2058 12.7477 14.5809C12.3726 14.956 11.8639 15.1667 11.3335 15.1667H4.66683C4.1364 15.1667 3.62769 14.956 3.25262 14.5809C2.87754 14.2058 2.66683 13.6971 2.66683 13.1667V9.16667C2.66683 8.63623 2.87754 8.12753 3.25262 7.75245C3.62769 7.37738 4.1364 7.16667 4.66683 7.16667H11.3335C11.8639 7.16667 12.3726 7.37738 12.7477 7.75245C13.1228 8.12753 13.3335 8.63623 13.3335 9.16667V13.1667Z"
                          fill="#7E849E"
                        />
                        <path
                          d="M7.99967 9.83203C7.82286 9.83203 7.65329 9.90227 7.52827 10.0273C7.40325 10.1523 7.33301 10.3219 7.33301 10.4987V11.832C7.33301 12.0088 7.40325 12.1784 7.52827 12.3034C7.65329 12.4285 7.82286 12.4987 7.99967 12.4987C8.17649 12.4987 8.34605 12.4285 8.47108 12.3034C8.5961 12.1784 8.66634 12.0088 8.66634 11.832V10.4987C8.66634 10.3219 8.5961 10.1523 8.47108 10.0273C8.34605 9.90227 8.17649 9.83203 7.99967 9.83203Z"
                          fill="#7E849E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4431_44218">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}
              </div>

              <div className="formAlertButtons">
                {dadosSecond?.subscription_active ? (
                  <>
                    <button type="submit" className="salvar">
                      <img src={disk} alt="disquete" />
                      <p>Salvar</p>
                    </button>
                    <button onClick={() => setAlertOpen(false)}>
                      <img src={cross} alt="xis" />
                      <p>Fechar</p>
                    </button>
                  </>
                ) : (
                  <>
                    <button disabled className="disabledButton">
                      <img src={disk} alt="disquete" />
                      <span>Salvar</span>
                    </button>
                    <button onClick={() => setAlertOpen(false)}>
                      <img src={cross} alt="xis" />
                      <p>Fechar</p>
                    </button>
                  </>
                )}
              </div>
            </div>
          </form>
        )}

        {dadosSecond &&
          dadosSecond.alerts.map((alert, index) => (
            <AlertCard
              setRecarregar={setRecarregar}
              recarregar={recarregar}
              key={index}
              alert={alert}
            />
          ))}
      </div>
    </StyledAlerta>
  );
}

export default Alerta;
