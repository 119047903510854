import Tag from "../UI/Tag";
import { StyledCardConcurso } from "../styles/CardConcurso";
import { Link } from "react-router-dom";
import {
  convertMoney,
  compararDatasNome,
  conversaoData,
} from "../tools/functions";
import SVGCardByName from "./SVGCardByName";

function CardConcurso({
  location_work_name,
  max_wage,
  number,
  short_name,
  registration_deadline,
  registration_opening,
  slug,
  stage,
  vacancies,
  category,
}) {
  const estados = {
    PR: "Paraná",
    AL: "Alagoas",
    PA: "Pará",
    AP: "Amapá",
    SE: "Sergipe",
    RN: "Rio Grande do Norte",
    SC: "Santa Catarina",
    RO: "Rondônia",
    TO: "Tocantins",
    MA: "Maranhão",
    PI: "Piauí",
    RS: "Rio Grande do Sul",
    MG: "Minas Gerais",
    SP: "São Paulo",
    AC: "Acre",
    PB: "Paraíba",
    RJ: "Rio de Janeiro",
    MS: "Mato Grosso do Sul",
    RR: "Roraima",
    GO: "Goiás",
    CE: "Ceará",
    ES: "Espírito Santo",
    PE: "Pernambuco",
    BA: "Bahia",
    DF: "Distrito Federal",
    MT: "Mato Grosso",
    AM: "Amazonas",
    Diversos: "Nacional",
  };

  return (
    <StyledCardConcurso className="CardConcurso">
      <div className="iconAndTag">
        <SVGCardByName name={category} />
        <Tag
          stage={stage}
          nome={compararDatasNome(registration_opening, registration_deadline)}
        />
      </div>

      <div className="tittle">
        <h2>{short_name}</h2>
        <h4>
          {location_work_name === "Diversos"
            ? "Nacional"
            : estados[location_work_name.slice(-2)]}
        </h4>
        <h4>{`Edital ${number}`}</h4>
      </div>
      <div className="divisaoInfo">
        <div className="InfoNumber">
          <div className="infoSubMenu">
            <p>salário até</p>
            <h2>{convertMoney(max_wage)}</h2>
          </div>
          <div className="infoSubMenu">
            <p>inscrições</p>
            <h2>
              {conversaoData(registration_opening)} a{" "}
              {conversaoData(registration_deadline)}
            </h2>
          </div>
          <div className="infoSubMenu">
            <p>vagas</p>
            <h2>{vacancies === 0 ? "Cadastro de reserva" : vacancies}</h2>
          </div>
        </div>
      </div>
      <div>
        <Link to={`/concurso/${slug}`}>
          <button className="saberMais">Saber mais</button>
        </Link>
      </div>
    </StyledCardConcurso>
  );
}

export default CardConcurso;
