import React, { useEffect, useState } from "react";
import { StyledFooterExpanded } from "../styles/FooterExpanded";
import logoPlaceholder from "../assets/logoPlaceholder.png";
import { Link } from "react-router-dom";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import linkedin from "../assets/linkedin.svg";
import facebookBlue from "../assets/facebookBlue.svg";
import instagramBlue from "../assets/instagramBlue.svg";
import linkedinBlue from "../assets/linkedinBlue.svg";
import logoFooterNew from "../assets/logoFooterNew.png";

function FooterExpanded({ page }) {
  const [formSend, setFormSend] = useState(false);
  const [email, setEmail] = useState("");
  const URL = process.env.REACT_APP_API_URL;
  const Token = process.env.REACT_APP_TOKEN;

  async function formSubmit(e) {
    e.preventDefault();
    fetch(`${URL}/events/free-newsletter-register/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${Token}`,
      },
      body: JSON.stringify({ email }),
    }).then((res) => {
      if (res.ok) {
        setEmail("");
        setFormSend(true);
      } else {
        console.error("Erro ao enviar o email:", res.statusText);
      }
    });
  }

  useEffect(() => {}, [page]);
  return (
    <StyledFooterExpanded $page={page}>
      <div className="footerContent">
        <div className="firstContent">
          <Link to={"/"} className="flexLogosvg">
            {page && page === "noticias" ? (
              <img
                style={{ width: "265px", height: "72px" }}
                src={logoFooterNew}
                alt="logo"
              />
            ) : (
              <>
                <img src={logoPlaceholder} alt="logo" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="171"
                  height="27"
                  viewBox="0 0 171 27"
                  fill="none"
                >
                  <path
                    d="M0.00378418 13.4867C0.00378418 5.27044 5.43579 0.509277 11.693 0.509277C14.8928 0.509277 17.4753 2.01713 19.1375 3.72094L16.187 7.31262C14.9463 6.15498 13.6105 5.34169 11.8177 5.34169C8.42192 5.34169 5.73858 8.3337 5.73858 13.3026C5.73858 18.2716 8.10727 21.3527 11.7286 21.3527C13.7589 21.3527 15.3618 20.3494 16.5729 19.0374L19.5234 22.5519C17.4693 24.9444 14.7326 26.1852 11.5268 26.1852C5.24584 26.1852 0.00378418 21.8336 0.00378418 13.4927V13.4867Z"
                    fill="#FCFDFF"
                  />
                  <path
                    d="M21.7244 16.3066C21.7244 10.0375 26.1353 6.42212 30.9024 6.42212C35.6695 6.42212 40.0863 10.0435 40.0863 16.3066C40.0863 22.5697 35.6754 26.1792 30.9024 26.1792C26.1293 26.1792 21.7244 22.5579 21.7244 16.3066ZM34.3634 16.3066C34.3634 13.0474 33.2177 10.928 30.9024 10.928C28.5871 10.928 27.4473 13.0474 27.4473 16.3066C27.4473 19.5658 28.593 21.6733 30.9024 21.6733C33.2117 21.6733 34.3634 19.5717 34.3634 16.3066Z"
                    fill="#FCFDFF"
                  />
                  <path
                    d="M43.9041 6.87327H48.4575L48.8612 9.2598H48.9978C50.5828 7.73409 52.4826 6.41614 55.0531 6.41614C59.1494 6.41614 60.9007 9.30731 60.9007 14.021V25.722H55.3143V14.7333C55.3143 12.0203 54.5841 11.1714 52.9753 11.1714C51.5743 11.1714 50.7135 11.8244 49.4846 12.9998V25.7161H43.8982V6.86733L43.9041 6.87327Z"
                    fill="#FCFDFF"
                  />
                  <path
                    d="M64.5407 16.3066C64.5407 10.0375 69.1535 6.42212 74.3599 6.42212C76.6811 6.42212 78.4621 7.24734 79.8691 8.45247L77.2332 12.0441C76.3309 11.2783 75.5769 10.9339 74.6805 10.9339C71.9971 10.9339 70.2696 13.0533 70.2696 16.3125C70.2696 19.5717 72.0684 21.6792 74.4905 21.6792C75.7253 21.6792 76.9126 21.0677 77.8922 20.2901L80.0769 23.9411C78.2424 25.5618 75.9034 26.1851 73.8612 26.1851C68.6014 26.1851 64.5407 22.5638 64.5407 16.3125V16.3066Z"
                    fill="#FCFDFF"
                  />
                  <path
                    d="M83.2009 18.5743V6.87329H88.7873V17.862C88.7873 20.575 89.5175 21.4239 91.1263 21.4239C92.5274 21.4239 93.3882 20.8362 94.4746 19.3521V6.87923H100.061V25.728H95.5076L95.1038 23.1159H94.9673C93.3941 24.9918 91.6191 26.1851 89.0485 26.1851C84.9523 26.1851 83.2009 23.294 83.2009 18.5803V18.5743Z"
                    fill="#FCFDFF"
                  />
                  <path
                    d="M104.963 6.87327H109.516L109.92 10.1681H110.056C111.428 7.65099 113.488 6.41614 115.411 6.41614C116.486 6.41614 117.115 6.55269 117.661 6.79609L116.729 11.6166C116.058 11.4504 115.506 11.3138 114.693 11.3138C113.274 11.3138 111.546 12.2281 110.543 14.7986V25.722H104.957V6.87327H104.963Z"
                    fill="#FCFDFF"
                  />
                  <path
                    d="M118.113 23.5492L120.624 20.0704C122.334 21.3645 123.907 22.0473 125.486 22.0473C127.148 22.0473 127.867 21.412 127.867 20.4265C127.867 19.1858 125.997 18.6218 124.061 17.862C121.752 16.9715 119.075 15.4339 119.075 12.3172C119.075 8.80863 121.924 6.41614 126.276 6.41614C129.167 6.41614 131.316 7.57973 132.919 8.80267L130.42 12.1569C129.078 11.1833 127.754 10.548 126.448 10.548C125.005 10.548 124.293 11.112 124.293 12.0559C124.293 13.2551 126.056 13.7182 128.009 14.4306C130.396 15.3151 133.085 16.6568 133.085 20.1001C133.085 23.5433 130.396 26.1792 125.344 26.1792C122.856 26.1792 120.019 25.1046 118.107 23.5492H118.113Z"
                    fill="#FCFDFF"
                  />
                  <path
                    d="M135.513 16.3066C135.513 10.0375 139.924 6.42212 144.691 6.42212C149.459 6.42212 153.875 10.0435 153.875 16.3066C153.875 22.5697 149.464 26.1792 144.691 26.1792C139.918 26.1792 135.513 22.5579 135.513 16.3066ZM148.158 16.3066C148.158 13.0474 147.013 10.928 144.697 10.928C142.382 10.928 141.242 13.0474 141.242 16.3066C141.242 19.5658 142.388 21.6733 144.697 21.6733C147.007 21.6733 148.158 19.5717 148.158 16.3066Z"
                    fill="#FCFDFF"
                  />
                  <path
                    d="M155.84 23.5492L158.351 20.0704C160.061 21.3645 161.634 22.0473 163.213 22.0473C164.875 22.0473 165.594 21.412 165.594 20.4265C165.594 19.1858 163.723 18.6218 161.788 17.862C159.479 16.9715 156.801 15.4339 156.801 12.3172C156.801 8.80863 159.651 6.41614 164.002 6.41614C166.894 6.41614 169.043 7.57973 170.646 8.80267L168.146 12.1569C166.805 11.1833 165.481 10.548 164.175 10.548C162.732 10.548 162.02 11.112 162.02 12.0559C162.02 13.2551 163.783 13.7182 165.736 14.4306C168.123 15.3151 170.812 16.6568 170.812 20.1001C170.812 23.5433 168.123 26.1792 163.07 26.1792C160.583 26.1792 157.745 25.1046 155.834 23.5492H155.84Z"
                    fill="#FCFDFF"
                  />
                </svg>
              </>
            )}
          </Link>
          <p>Todos os concursos em um só lugar</p>
        </div>
        <div className="tripleFlex">
          <div className="menuFlex">
            <span>Menu</span>
            <Link to="/">Início</Link>
            <Link to="/cargos">Cargos</Link>
            <Link to="/concursos">Concursos</Link>
            <Link to="/sobre">Sobre</Link>
            <Link to="/planos">Premium</Link>
          </div>
          <div className="termos">
            <div>
              <span>Termos de serviço</span>
              <Link to="/termos" state={{ from: "termos" }}>
                Termos e condições
              </Link>
              <Link to="/termos" state={{ from: "politica" }}>
                Política de privacidade
              </Link>
            </div>
            <div>
              <span>Minha conta</span>
              <Link to="/conta" state={{ from: "conta" }}>
                Conta
              </Link>
              <Link to="/conta" state={{ from: "assinatura" }}>
                Assinatura
              </Link>
            </div>
          </div>
          <div className="contato">
            <div className="row">
              <span>Contato</span>
              <a className="email" href="mailto:admin@aloconcursos.com">
                admin@aloconcursos.com
              </a>
            </div>
            <div className="row">
              <span>Redes sociais</span>
              <div className="flexSocial">
                <div>
                  <Link to="https://www.facebook.com/profile.php?id=61565931664974">
                    <img
                      src={page?.includes("noticias") ? facebookBlue : facebook}
                      alt="facebook"
                    />
                  </Link>
                </div>
                <div>
                  <Link to="https://www.instagram.com/alo.concursos/">
                    <img
                      src={
                        page?.includes("noticias") ? instagramBlue : instagram
                      }
                      alt="instagram"
                    />
                  </Link>
                </div>
                <div>
                  <Link to="https://www.linkedin.com/company/alo-concursos/posts/?feedView=all">
                    <img
                      src={page?.includes("noticias") ? linkedinBlue : linkedin}
                      alt="linkedin"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tripleFlexFin">
          <div className="menuFlex">
            <span>Menu</span>
            <Link to="/">Início</Link>
            <Link to="/cargos">Cargos</Link>
            <Link to="/concursos">Concursos</Link>
            <Link to="/sobre">Sobre</Link>
            <Link to="/planos">Premium</Link>
          </div>
          <div className="termos">
            <div>
              <span>Termos de serviço</span>
              <Link to="/termos" state={{ from: "termos" }}>
                Termos e condições
              </Link>
              <Link to="/termos" state={{ from: "politica" }}>
                Política de privacidade
              </Link>
            </div>
          </div>
        </div>
        <div className="tripleFlexFin2">
          <div className="menuFlex">
            <span>Minha conta</span>
            <Link to="/conta" state={{ from: "conta" }}>
              Conta
            </Link>
            <Link to="/conta" state={{ from: "assinatura" }}>
              Assinatura
            </Link>
          </div>
          <div className="contato">
            <div className="row">
              <span>Contato</span>
              <a className="email" href="mailto:admin@aloconcursos.com">
                admin@aloconcursos.com
              </a>
            </div>
            <div className="row">
              <span>Redes sociais</span>
              <div className="flexSocial">
                <div>
                  <Link to="https://www.facebook.com/profile.php?id=61565931664974">
                    <img
                      src={page?.includes("noticias") ? facebookBlue : facebook}
                      alt="facebook"
                    />
                  </Link>
                </div>
                <div>
                  <Link to="https://www.instagram.com/alo.concursos/">
                    <img
                      src={
                        page?.includes("noticias") ? instagramBlue : instagram
                      }
                      alt="instagram"
                    />
                  </Link>
                </div>
                <div>
                  <Link to="https://www.linkedin.com/company/alo-concursos/posts/?feedView=all">
                    <img
                      src={page?.includes("noticias") ? linkedinBlue : linkedin}
                      alt="linkedin"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {formSend ? (
          <h1 className="newsLetterConfirmation">
            Tudo certo, manteremos você atualizado!
          </h1>
        ) : (
          <form className="newsLetterBox" onSubmit={formSubmit}>
            <div className="flexNewsLetter">
              <div className="flexTittleNews">
                <h2>Mantenha-se informado, fique à frente</h2>
                <p>
                  Inscreva-se na nossa newsletter para as últimas atualizações.
                </p>
              </div>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Insira seu e-mail"
                required
              />
            </div>
            <button type="submit">Inscrever</button>
          </form>
        )}
      </div>

      <div className="final">
        <p>© Alô Concursos 2024. Todos os direitos reservados.</p>
      </div>

      {/* INSERIR DEPOIS */}
    </StyledFooterExpanded>
  );
}

export default FooterExpanded;
