import React from "react";
import EditalPublicado from "../assets/editalPublicado.svg";
import InscricoesAbertas from "../assets/inscricoesAbertas.svg";
import InscricoesEncerradas from "../assets/inscricoesEncerradas.svg";
import { StyledTag } from "../styles/Tag";
import { useNavigate } from "react-router-dom";

function Tag({
  classname,
  setRecarregarComTag,
  recarregarComTag,
  stage,
  nome,
}) {
  const stageImages = {
    EditalPublicado: EditalPublicado,
    InscricoesAbertas: InscricoesAbertas,
    InscricoesEncerradas: InscricoesEncerradas,
  };

  const nomeLista = {
    EditalPublicado: "Edital Publicado",
    InscricoesAbertas: "Inscrições Abertas",
    InscricoesEncerradas: "Inscrições Encerradas",
  };

  const navigate = useNavigate();
  function recarregar() {
    navigate(`/cargos/${nome}`);
    if (setRecarregarComTag) {
      setRecarregarComTag(!recarregarComTag);
    }
  }

  return (
    <StyledTag
      className={`Tag ${classname === "tagNormal" ? "tagNormal" : stage}`}
      onClick={classname === "tagNormal" ? recarregar : null}
    >
      {classname !== "tagNormal" ? (
        <img src={stage ? stageImages[stage] : null} alt={nome} />
      ) : null}
      <p>{classname === "tagNormal" ? nome : nomeLista[stage]}</p>
    </StyledTag>
  );
}

export default Tag;
