import styled from "styled-components";

export const StyledFooterExpanded = styled.footer`
  /* background-color: var(--Grey-900); */
  border-top: ${(props) =>
    props.$page === "noticias" ? "2px solid #EDEEFA" : "none"};
  background-color: ${(props) =>
    props.$page === "noticias" ? "#FFFFFF" : "#252A3D"};
  padding-top: 4.8rem;
  .footerContent {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: 1664px;
    /* height: 250px; */
    .firstContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Scale-4, 32px);

      .flexLogosvg {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        img {
          width: 74.297px;
          height: 62.198px;
        }
        svg {
          position: relative;
          top: -0.8rem;
          width: 170.808px;
          height: 25.676px;
        }
      }

      p {
        color: ${(props) =>
          props.$page === "noticias" ? "#33394D" : "#edeefa"};
        width: 174px;
        font-family: "Work Sans";
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
        letter-spacing: 0.18px;
      }
    }
    .tripleFlex {
      display: flex;
      align-items: flex-start;
      gap: 128px;
      margin-bottom: 4.8rem;
      .menuFlex {
        max-width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Scale-2, 16px);
        span {
          color: ${(props) =>
            props.$page === "noticias" ? "#2A2E40" : "#f7f8ff"};

          /* Text/Extra Large/Bold */
          font-family: "Work Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 25.2px */
        }
        a {
          text-decoration: none;
          color: ${(props) =>
            props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
          &:visited {
            color: ${(props) =>
              props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};
            text-decoration: none;
          }
        }
      }
      .termos {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4.8rem;
        div {
          max-width: fit-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Scale-2, 16px);
          span {
            color: ${(props) =>
              props.$page === "noticias" ? "#2A2E40" : "#f7f8ff"};
            /* Text/Extra Large/Bold */
            font-family: "Work Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 25.2px */
          }
          a {
            text-decoration: none;
            color: ${(props) =>
              props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};

            /* Text/Large/Regular */
            font-family: "Work Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.16px;
            &:visited {
              color: ${(props) =>
                props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};
              text-decoration: none;
            }
          }
        }
      }
      .contato {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4.8rem;
        .row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Scale-2, 16px);
          span {
            color: ${(props) =>
              props.$page === "noticias" ? "#2A2E40" : "#f7f8ff"};
            /* Text/Extra Large/Bold */
            font-family: "Work Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 25.2px */
          }
          .email {
            text-decoration: none;
            color: ${(props) =>
              props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};

            /* Text/Large/Regular */
            font-family: "Work Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.16px;
            &:visited {
              color: ${(props) =>
                props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};
              text-decoration: none;
            }
          }
          .flexSocial {
            display: flex;
            align-items: center;
            gap: var(--Scale-2, 16px);
            div {
              display: flex;
              padding: var(--Radius-md, 8px);
              align-items: center;
              border-radius: var(--Radius-md, 8px);
              border: 1px solid var(--Primary-200, #aaaaf0);
            }
          }
        }
      }
    }
  }

  .newsLetterBox {
    max-width: 36rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    .flexNewsLetter {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .flexTittleNews {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }
    h2 {
      color: ${(props) => (props.$page === "noticias" ? "#33394d" : "#f7f8ff")};

      /* Text/Extra Large/Bold */
      font-family: "Work Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 25.2px */
    }
    p {
      color: ${(props) => (props.$page === "noticias" ? "#495066" : "#aaaaf0")};
      /* Text/Large/Regular */
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.16px;
    }
    input {
      display: flex;
      height: var(--Scale-6, 48px);
      padding: 10px 16px;
      align-items: center;
      gap: 12px;
      background-color: ${(props) =>
        props.$page === "noticias" ? "#FFF" : "#252a3d"};
      color: ${(props) => (props.$page === "noticias" ? "#7E849E" : "#edeefa")};

      outline: none;
      border-radius: 8px;
      border: 1px solid #7e849e;
      &::placeholder {
        color: #606780;
      }
    }
    button {
      width: 121px;
      display: flex;
      height: var(--Scale-6, 48px);
      padding: 12px var(--Scale-3, 24px);
      align-items: center;
      gap: var(--Scale-2, 16px);
      border-radius: var(--Radius-md, 8px);
      background: ${(props) =>
        props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};
      color: ${(props) => (props.$page === "noticias" ? "#FFF" : "#252A3D")};
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.16px;
      border: none;
      cursor: pointer;
    }
  }
  .final {
    border-top: ${(props) =>
      props.$page === "noticias" ? "1px solid #EDEEFA" : "1px solid #495066"};
    display: flex;
    padding: var(--Scale-3, 24px) var(--Radius-md, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--Radius-md, 8px);

    p {
      color: var(--Grey-300, #a0a5bd);
      width: 1440px;
      /* Text/Large/Regular */
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.16px;
    }
  }

  .tripleFlexFin {
    display: none;
  }
  .tripleFlexFin2 {
    display: none;
  }

  @media (max-width: 1700px) {
    .footerContent {
      width: 90%;
      .tripleFlex {
        gap: 6.4rem;
      }
    }
  }

  @media (max-width: 1399px) {
    .footerContent {
    }
    .tripleFlex {
      display: none !important;
    }
    .tripleFlexFin {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 64px;
      margin-bottom: 4.8rem;
      .menuFlex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Scale-2, 16px);
        span {
          color: ${(props) =>
            props.$page === "noticias" ? "#2A2E40" : "#f7f8ff"};

          /* Text/Extra Large/Bold */
          font-family: "Work Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 25.2px */
        }
        a {
          text-decoration: none;
          color: ${(props) =>
            props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
          &:visited {
            color: ${(props) =>
              props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};
            text-decoration: none;
          }
        }
      }
      .termos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4.8rem;
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Scale-2, 16px);
          span {
            color: ${(props) =>
              props.$page === "noticias" ? "#2A2E40" : "#f7f8ff"};
            /* Text/Extra Large/Bold */
            font-family: "Work Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 25.2px */
          }
          a {
            text-decoration: none;
            color: ${(props) =>
              props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};

            /* Text/Large/Regular */
            font-family: "Work Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.16px;
            &:visited {
              color: ${(props) =>
                props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};
              text-decoration: none;
            }
          }
        }
      }
      .contato {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4.8rem;
        .row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Scale-2, 16px);
          span {
            color: ${(props) =>
              props.$page === "noticias" ? "#2A2E40" : "#f7f8ff"};
            /* Text/Extra Large/Bold */
            font-family: "Work Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 25.2px */
          }
          .email {
            text-decoration: none;
            color: ${(props) =>
              props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};

            /* Text/Large/Regular */
            font-family: "Work Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.16px;
            &:visited {
              color: ${(props) =>
                props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};
              text-decoration: none;
            }
          }
          .flexSocial {
            display: flex;
            align-items: center;
            gap: var(--Scale-2, 16px);
            div {
              display: flex;
              padding: var(--Radius-md, 8px);
              align-items: center;
              border-radius: var(--Radius-md, 8px);
              border: 1px solid var(--Primary-200, #aaaaf0);
            }
          }
        }
      }
    }

    .tripleFlexFin2 {
      max-width: 208px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 64px;
      margin-bottom: 4.8rem;
      .menuFlex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Scale-2, 16px);
        span {
          color: ${(props) =>
            props.$page === "noticias" ? "#2A2E40" : "#f7f8ff"};
          width: fit-content;
          /* Text/Extra Large/Bold */
          font-family: "Work Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 25.2px */
        }
        a {
          text-decoration: none;
          color: ${(props) =>
            props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
          &:visited {
            color: ${(props) =>
              props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};
            text-decoration: none;
          }
        }
      }
      .termos {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4.8rem;
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Scale-2, 16px);
          span {
            color: ${(props) =>
              props.$page === "noticias" ? "#2A2E40" : "#f7f8ff"};
            /* Text/Extra Large/Bold */
            font-family: "Work Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 25.2px */
          }
          a {
            text-decoration: none;
            color: ${(props) =>
              props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};

            /* Text/Large/Regular */
            font-family: "Work Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.16px;
            &:visited {
              color: var(--Primary-200, #aaaaf0);
              text-decoration: none;
            }
          }
        }
      }
      .contato {
        width: 288px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4.8rem;
        .row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Scale-2, 16px);
          span {
            color: ${(props) =>
              props.$page === "noticias" ? "#2A2E40" : "#f7f8ff"};
            /* Text/Extra Large/Bold */
            font-family: "Work Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 25.2px */
          }
          .email {
            text-decoration: none;
            color: ${(props) =>
              props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};

            /* Text/Large/Regular */
            font-family: "Work Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.16px;
            &:visited {
              color: ${(props) =>
                props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};
              text-decoration: none;
            }
          }
          .flexSocial {
            display: flex;
            align-items: center;
            gap: var(--Scale-2, 16px);
            div {
              display: flex;
              padding: var(--Radius-md, 8px);
              align-items: center;
              border-radius: var(--Radius-md, 8px);
              border: 1px solid
                ${(props) =>
                  props.$page === "noticias" ? "#1B2CC1" : "#aaaaf0"};
            }
          }
        }
      }
    }
  }

  .newsLetterConfirmation {
    color: ${(props) => (props.$page === "noticias" ? "#2A2E40" : "#f7f8ff")};
    width: fit-content;
    /* Text/Extra Large/Bold */
    font-family: "Work Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    max-width: 300px;
  }

  @media (max-width: 1199px) {
    .tripleFlexFin2 {
      display: none;
    }
    .tripleFlexFin {
      display: none;
    }
    .tripleFlex {
      display: flex !important;
      flex-direction: column;
      margin-bottom: 0 !important;
    }
    .footerContent {
      flex-direction: column;
      gap: 6.4rem;
    }
    .newsLetterBox {
      margin-bottom: 4.8rem;
    }
  }
  @media (max-width: 450px) {
    .final {
      max-width: 328px;
    }
  }
`;
