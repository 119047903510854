import { useNavigate } from "react-router-dom";
import {
  conversaoData,
  compararDatasClasse,
  compararDatasNome,
} from "../tools/functions";
import Tag from "../UI/Tag";
import { StyledCardSlide } from "../styles/CardSlide";
import { toTitleCase } from "../tools/functions";
import SVGCardByName from "./SVGCardByName";

function CardSlide({
  short_name,
  contest_short_name,
  organization_short_name,
  wage,
  stage,
  vacancies,
  registration_deadline,
  category,
  contest_slug,
  tags,
  recarregarComTag,
  setRecarregarComTag,
}) {
  const navigate = useNavigate();

  return (
    <StyledCardSlide className="CardSlide">
      <div className="Cabecalho">
        <SVGCardByName name={category} />
        <Tag
          stage={stage}
          setRecarregarComTag={setRecarregarComTag}
          recarregarComTag={recarregarComTag}
        />
      </div>
      <div className="Titulo">
        <h2>{toTitleCase(short_name)}</h2>
        <h3>{contest_short_name}</h3>
      </div>
      <div className="tagCargo">
        {tags
          ? tags.map((tag, index) => {
              if (index < 2) {
                return (
                  <Tag
                    stage={stage}
                    setRecarregarComTag={setRecarregarComTag}
                    recarregarComTag={recarregarComTag}
                    key={index}
                    nome={tag.name}
                    classname="tagNormal"
                  />
                );
              }
              return null;
            })
          : null}
      </div>

      <div className="flexDe4slots">
        <div className="flexde2slots">
          <div>
            <h3>Vagas:</h3>
            <p>{vacancies}</p>
          </div>
          <div>
            <h3>Salário:</h3>
            <p>{wage}</p>
          </div>
        </div>
        <div className="flexde2slots">
          <div>
            <h3>Banca:</h3>
            <p>{organization_short_name}</p>
          </div>
          <div>
            <h3>Inscrição até:</h3>
            <p>{conversaoData(registration_deadline)}</p>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          navigate(`/concurso/${contest_slug}`);
        }}
      >
        Confira o concurso
      </button>
    </StyledCardSlide>
  );
}

export default CardSlide;
