import React from "react";

function SVGCardByName({ name }) {
  const svgList = {
    General: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={33}
        fill="none"
      >
        <g clipPath="url(#a)">
          <mask
            id="b"
            width={33}
            height={33}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.335.333h32v32h-32v-32Z" />
          </mask>
          <g
            stroke="#606780"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.875}
            mask="url(#b)"
          >
            <path d="M31.398 8.83v20.626H1.271V8.83" />
            <path d="M14.46 18.206H8.553c-1.023 0-7.28.123-7.28-9.376 0-1.035.839-1.875 1.874-1.875h26.376c1.035 0 1.875.84 1.875 1.875 0 9.62-6.298 9.376-7.281 9.376H18.21" />
            <path d="M18.21 21.956h-3.75V16.33h3.75v5.625ZM10.71 6.956V5.08c0-1.036.84-1.875 1.875-1.875h7.5c1.035 0 1.875.84 1.875 1.875v1.875" />
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.333.333h32v32h-32z" />
          </clipPath>
        </defs>
      </svg>
    ),
    Saude: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={32}
        fill="none"
      >
        <g clipPath="url(#a)">
          <path
            stroke="#606780"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.875}
            d="M25.749 12.544h-5.981V6.563h-6.913v5.981H6.874v6.912h5.981v5.982h6.913v-5.982h5.981v-6.912Z"
          />
          <mask
            id="b"
            width={33}
            height={33}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.312.002h32v32h-32v-32Z" />
          </mask>
          <g mask="url(#b)">
            <path
              stroke="#606780"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={10}
              strokeWidth={1.875}
              d="M31.375 16.002c0 8.319-6.744 15.063-15.063 15.063S1.25 24.32 1.25 16.002 7.993.94 16.312.94c8.319 0 15.063 6.743 15.063 15.062Z"
            />
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.312 0h32v32h-32z" />
          </clipPath>
        </defs>
      </svg>
    ),
    Medicina: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={32}
        fill="none"
      >
        <g clipPath="url(#a)">
          <path
            fill="#606780"
            d="M29.84 16.366v-3.881c0-3.203-2.566-5.808-5.72-5.808-3.153 0-5.72 2.605-5.72 5.808v13.668c0 2.141-1.702 3.884-3.795 3.884H12.2c-2.093 0-3.796-1.743-3.796-3.884v-3.538c3.408-.47 6.04-3.401 6.04-6.936V.95h-3.006V.04H9.513v3.748h1.924v-.912h1.083v11.842H2.363V2.875h1.093v.912H5.38V.039H3.456v.912H.44V15.68c0 3.534 2.633 6.465 6.04 6.936v3.538c0 3.202 2.566 5.808 5.72 5.808h2.406c3.154 0 5.72-2.606 5.72-5.808V12.485c0-2.142 1.703-3.885 3.796-3.885 2.092 0 3.795 1.743 3.795 3.885v3.88a3.567 3.567 0 0 0-2.599 3.429 3.565 3.565 0 0 0 3.561 3.56 3.565 3.565 0 0 0 3.56-3.56 3.567 3.567 0 0 0-2.598-3.428Zm-27.385.275h9.972a5.086 5.086 0 0 1-4.986 4.116 5.086 5.086 0 0 1-4.986-4.116Zm27.535 4.265h-2.224V18.68h2.224v2.225Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.439 0h32v32h-32z" />
          </clipPath>
        </defs>
      </svg>
    ),
    Odontologia: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={33}
        fill="none"
      >
        <g clipPath="url(#a)">
          <mask
            id="b"
            width={33}
            height={33}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.335.333h32v32h-32v-32Z" />
          </mask>
          <g
            stroke="#606780"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.875}
            mask="url(#b)"
          >
            <path d="M31.398 8.83v20.626H1.271V8.83" />
            <path d="M14.46 18.206H8.553c-1.023 0-7.28.123-7.28-9.376 0-1.035.839-1.875 1.874-1.875h26.376c1.035 0 1.875.84 1.875 1.875 0 9.62-6.298 9.376-7.281 9.376H18.21" />
            <path d="M18.21 21.956h-3.75V16.33h3.75v5.625ZM10.71 6.956V5.08c0-1.036.84-1.875 1.875-1.875h7.5c1.035 0 1.875.84 1.875 1.875v1.875" />
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.333.333h32v32h-32z" />
          </clipPath>
        </defs>
      </svg>
    ),
    Ensino: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={32}
        fill="none"
      >
        <g fill="#606780" clipPath="url(#a)">
          <path d="M8.501-.002c-2.084 0-3.75 1.75-3.75 3.813a3.754 3.754 0 0 0 3.75 3.75 3.754 3.754 0 0 0 3.75-3.75c0-2.064-1.667-3.813-3.75-3.813Zm0 5.688A1.877 1.877 0 0 1 6.626 3.81c0-1.05.86-1.938 1.875-1.938 1.017 0 1.876.887 1.876 1.938A1.877 1.877 0 0 1 8.5 5.686Z" />
          <path d="M29.251-.002h-13.25a2.816 2.816 0 0 0-2.812 2.813v6.397l-.824-.824a2.794 2.794 0 0 0-1.989-.823H4.751a4.693 4.693 0 0 0-4.687 4.687v5.625a2.816 2.816 0 0 0 3.75 2.652v8.66a2.816 2.816 0 0 0 2.812 2.813c.72 0 1.378-.272 1.875-.718a2.801 2.801 0 0 0 1.875.718 2.816 2.816 0 0 0 2.813-2.813v-12.41c.717.254 1.51.205 2.195-.136l3.157-1.579h10.71a2.816 2.816 0 0 0 2.813-2.812V2.811A2.816 2.816 0 0 0 29.25-.002ZM18.296 13.087l-3.75 1.874a.937.937 0 0 1-1.082-.175l-.55-.55c-.58-.58-1.598-.185-1.6.66V29.187a.939.939 0 0 1-1.875 0v-9.438a.938.938 0 0 0-1.875 0v9.438a.939.939 0 0 1-1.875 0V12.247a.938.938 0 0 0-1.875 0v5.625a.939.939 0 0 1-1.875 0v-5.625a2.816 2.816 0 0 1 2.812-2.812h5.625c.25 0 .486.097.663.274l2.794 2.794a.938.938 0 0 0 1.082.176l2.542-1.27a.937.937 0 1 1 .838 1.678Zm11.893-.839c0 .517-.42.938-.938.938h-8.723l.017-.049c.135-.405.175-.826.124-1.237l5.127-2.563a.937.937 0 1 0-.839-1.677l-5.126 2.563a2.81 2.81 0 0 0-3.212-.49l-1.555.777v-7.7c0-.516.42-.937.937-.937h13.25c.517 0 .938.42.938.938v9.437Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.063 0h32v32h-32z" />
          </clipPath>
        </defs>
      </svg>
    ),
    Tecnologia: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={33}
        fill="none"
      >
        <g clipPath="url(#a)">
          <mask
            id="b"
            width={33}
            height={33}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.335.333h32v32h-32v-32Z" />
          </mask>
          <g
            stroke="#606780"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.875}
            mask="url(#b)"
          >
            <path d="M31.398 8.83v20.626H1.271V8.83" />
            <path d="M14.46 18.206H8.553c-1.023 0-7.28.123-7.28-9.376 0-1.035.839-1.875 1.874-1.875h26.376c1.035 0 1.875.84 1.875 1.875 0 9.62-6.298 9.376-7.281 9.376H18.21" />
            <path d="M18.21 21.956h-3.75V16.33h3.75v5.625ZM10.71 6.956V5.08c0-1.036.84-1.875 1.875-1.875h7.5c1.035 0 1.875.84 1.875 1.875v1.875" />
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.333.333h32v32h-32z" />
          </clipPath>
        </defs>
      </svg>
    ),
    Engenharia: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={33}
        fill="none"
      >
        <g clipPath="url(#a)">
          <mask
            id="b"
            width={33}
            height={33}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.335.333h32v32h-32v-32Z" />
          </mask>
          <g
            stroke="#606780"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.875}
            mask="url(#b)"
          >
            <path d="M31.398 8.83v20.626H1.271V8.83" />
            <path d="M14.46 18.206H8.553c-1.023 0-7.28.123-7.28-9.376 0-1.035.839-1.875 1.874-1.875h26.376c1.035 0 1.875.84 1.875 1.875 0 9.62-6.298 9.376-7.281 9.376H18.21" />
            <path d="M18.21 21.956h-3.75V16.33h3.75v5.625ZM10.71 6.956V5.08c0-1.036.84-1.875 1.875-1.875h7.5c1.035 0 1.875.84 1.875 1.875v1.875" />
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.333.333h32v32h-32z" />
          </clipPath>
        </defs>
      </svg>
    ),
    Financeiro: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={32}
        fill="none"
      >
        <g fill="#606780" clipPath="url(#a)">
          <path d="M29.75 1.81H3.375A2.816 2.816 0 0 0 .563 4.624v16.75a2.816 2.816 0 0 0 2.812 2.813h9.25v4.125H6.564a.937.937 0 1 0 0 1.875h20a.938.938 0 0 0 0-1.875H20.5v-4.125h9.25a2.816 2.816 0 0 0 2.813-2.813V4.623a2.816 2.816 0 0 0-2.813-2.812Zm-11.125 26.5h-4.124v-4.124h4.124v4.125Zm12.063-6.937c0 .517-.42.938-.938.938H3.375a.939.939 0 0 1-.937-.938V4.623c0-.517.42-.937.937-.937H29.75c.517 0 .938.42.938.937v16.75Z" />
          <path d="M26.563 5.81h-4a.937.937 0 0 0-.938.938v1.063h-3.063a.937.937 0 0 0-.937.937v1.063h-3.063a.937.937 0 0 0-.937.937v8.5c0 .518.42.938.938.938h12c.517 0 .937-.42.937-.938v-12.5a.938.938 0 0 0-.938-.937ZM15.5 11.686h2.125v6.626H15.5v-6.626Zm4-2h2.125v8.626H19.5V9.686Zm6.125 8.626H23.5V7.686h2.125V18.31ZM8.602 14.073c-.467-.166-1.229-.443-1.61-.742-.023-.019-.067-.127-.036-.29.014-.077.081-.336.333-.412.388-.117.757-.04.999.139a.937.937 0 0 0 1.268-1.38S9.12 11 8.5 10.817v-.078a.938.938 0 0 0-1.875 0v.136c-1.685.613-2.081 2.918-.79 3.93.588.461 1.428.782 2.142 1.034.464.165.512.498.47.749-.047.279-.288.752-.89.756-.626.004-.774-.02-1.23-.318A.938.938 0 1 0 5.3 18.596c.498.326.893.488 1.325.564v.09a.938.938 0 0 0 1.875 0v-.197c2.271-.82 2.573-4.106.102-4.98Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.562 0h32v32h-32z" />
          </clipPath>
        </defs>
      </svg>
    ),
    Juridico: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={33}
        fill="none"
      >
        <g clipPath="url(#a)">
          <mask
            id="b"
            width={33}
            height={33}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.335.333h32v32h-32v-32Z" />
          </mask>
          <g
            stroke="#606780"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.875}
            mask="url(#b)"
          >
            <path d="M31.398 8.83v20.626H1.271V8.83" />
            <path d="M14.46 18.206H8.553c-1.023 0-7.28.123-7.28-9.376 0-1.035.839-1.875 1.874-1.875h26.376c1.035 0 1.875.84 1.875 1.875 0 9.62-6.298 9.376-7.281 9.376H18.21" />
            <path d="M18.21 21.956h-3.75V16.33h3.75v5.625ZM10.71 6.956V5.08c0-1.036.84-1.875 1.875-1.875h7.5c1.035 0 1.875.84 1.875 1.875v1.875" />
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.333.333h32v32h-32z" />
          </clipPath>
        </defs>
      </svg>
    ),
    Militar: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={33}
        fill="none"
      >
        <g clipPath="url(#a)">
          <mask
            id="b"
            width={33}
            height={33}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.335.333h32v32h-32v-32Z" />
          </mask>
          <g
            stroke="#606780"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.875}
            mask="url(#b)"
          >
            <path d="M31.398 8.83v20.626H1.271V8.83" />
            <path d="M14.46 18.206H8.553c-1.023 0-7.28.123-7.28-9.376 0-1.035.839-1.875 1.874-1.875h26.376c1.035 0 1.875.84 1.875 1.875 0 9.62-6.298 9.376-7.281 9.376H18.21" />
            <path d="M18.21 21.956h-3.75V16.33h3.75v5.625ZM10.71 6.956V5.08c0-1.036.84-1.875 1.875-1.875h7.5c1.035 0 1.875.84 1.875 1.875v1.875" />
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.333.333h32v32h-32z" />
          </clipPath>
        </defs>
      </svg>
    ),
  };
  return <div>{svgList[name]}</div>;
}

export default SVGCardByName;
