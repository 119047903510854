import React, { useState, useEffect } from "react";
import { StyledHomepage } from "../styles/Homepage";
import home_hero_background from "../assets/home_hero_background.jpg";
import SecondSearchBar from "../components/SecondSearchBar";
import { Link, useLocation } from "react-router-dom";
import features_background from "../assets/features_background.jpg";
import checkCirclee from "../assets/checkCirclee.svg";
import timeFast from "../assets/timeFast.svg";
import add from "../assets/add.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import SlideSwiper from "../components/SlideSwiper";
import AboutCards from "../UI/AboutCards";
import HowWorks from "../assets/HowWorks.jpg";
import TutorialBox from "../UI/TutorialBox";
import passo1 from "../assets/passo1.png";
import passo2 from "../assets/passo2.png";
import passo3 from "../assets/passo3.png";
import newStatistics from "../assets/newStatistics.jpg";
import HomepageStatistics from "../UI/HomepageStatistics";
import { convertKMoney, conversaoDia, formatMilliar } from "../tools/functions";
import CTA_background from "../assets/CTA_background.jpg";
import Skeleton from "../UI/Skeleton";

function Homepage({ setUrlAtual, setTermoPesquisado, setSearchReset }) {
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;
  const [renderizacao, setRenderizacao] = useState(null);
  const [aloData, setAloData] = useState(null);
  const queryForApi = `${URL}/office/slide-cards/`;
  const queryForApiData = `${URL}/analytics/analytics/`;
  const location = useLocation();
  const [recarregarComTag, setRecarregarComTag] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState("cargos");
  const [suggestion, setSuggestion] = useState([
    "Prefeitura",
    "Banco",
    "Federal",
  ]);

  useEffect(() => {
    document.title = "Alô Concursos";
    setUrlAtual(location.pathname);
  }, []);

  useEffect(() => {
    async function requisicao(apiLink) {
      fetch(apiLink, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          setRenderizacao(response.results);
        });
    }

    requisicao(queryForApi);
  }, [queryForApi, Token]);

  useEffect(() => {
    async function requisicaoDados(apiLink) {
      fetch(apiLink, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          setAloData(response);
        });
    }

    requisicaoDados(queryForApiData);
  }, [queryForApiData, Token]);
  return (
    <StyledHomepage>
      <header style={{ backgroundImage: `url(${home_hero_background})` }}>
        <div className="flexLogoSearch">
          <div className="logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="196"
              height="114"
              viewBox="0 0 196 114"
              fill="none"
            >
              <g clipPath="url(#clip0_3512_4347)">
                <path
                  d="M0.33667 98.7696C0.33667 89.3779 6.54582 83.9355 13.6982 83.9355C17.3558 83.9355 20.3078 85.6591 22.2078 87.6067L18.8352 91.7122C17.4169 90.389 15.8901 89.4593 13.8407 89.4593C9.95917 89.4593 6.89192 92.8794 6.89192 98.5592C6.89192 104.239 9.5995 107.761 13.7389 107.761C16.0597 107.761 17.892 106.614 19.2763 105.114L22.6489 109.132C20.301 111.867 17.1726 113.285 13.5082 113.285C6.32869 113.285 0.33667 108.311 0.33667 98.7764V98.7696Z"
                  fill="white"
                />
                <path
                  d="M25.1664 101.993C25.1664 94.8269 30.2084 90.6942 35.6575 90.6942C41.1067 90.6942 46.1554 94.8337 46.1554 101.993C46.1554 109.152 41.1135 113.278 35.6575 113.278C30.2016 113.278 25.1664 109.138 25.1664 101.993ZM39.6137 101.993C39.6137 98.2674 38.3041 95.8447 35.6575 95.8447C33.011 95.8447 31.7081 98.2674 31.7081 101.993C31.7081 105.718 33.0178 108.127 35.6575 108.127C38.2973 108.127 39.6137 105.725 39.6137 101.993Z"
                  fill="white"
                />
                <path
                  d="M50.5186 91.2099H55.7235L56.1849 93.9379H56.341C58.1528 92.1939 60.3244 90.6874 63.2627 90.6874C67.945 90.6874 69.9468 93.9922 69.9468 99.3802V112.755H63.5613V100.195C63.5613 97.0933 62.7266 96.1229 60.8876 96.1229C59.2861 96.1229 58.3021 96.8694 56.8974 98.213V112.749H50.5118V91.2031L50.5186 91.2099Z"
                  fill="white"
                />
                <path
                  d="M74.1067 101.993C74.1067 94.8269 79.3794 90.6942 85.3306 90.6942C87.984 90.6942 90.0198 91.6375 91.628 93.015L88.6151 97.1205C87.5836 96.2451 86.7218 95.8515 85.6971 95.8515C82.6299 95.8515 80.6552 98.2741 80.6552 102C80.6552 105.725 82.7113 108.134 85.48 108.134C86.8914 108.134 88.2486 107.435 89.3683 106.546L91.8655 110.72C89.7687 112.572 87.095 113.285 84.7606 113.285C78.7483 113.285 74.1067 109.145 74.1067 102V101.993Z"
                  fill="white"
                />
                <path
                  d="M95.4349 104.585V91.21H101.821V103.771C101.821 106.872 102.655 107.842 104.494 107.842C106.096 107.842 107.08 107.171 108.321 105.474V91.2168H114.707V112.762H109.502L109.041 109.776H108.885C107.086 111.921 105.057 113.285 102.119 113.285C97.4368 113.285 95.4349 109.98 95.4349 104.592V104.585Z"
                  fill="white"
                />
                <path
                  d="M120.312 91.2099H125.517L125.979 94.9761H126.135C127.702 92.0989 130.057 90.6874 132.256 90.6874C133.484 90.6874 134.203 90.8435 134.827 91.1217L133.762 96.6319C132.995 96.4419 132.364 96.2858 131.434 96.2858C129.813 96.2858 127.838 97.3308 126.691 100.269V112.755H120.306V91.2099H120.312Z"
                  fill="white"
                />
                <path
                  d="M135.343 110.272L138.214 106.295C140.168 107.774 141.966 108.555 143.771 108.555C145.671 108.555 146.492 107.829 146.492 106.702C146.492 105.284 144.355 104.639 142.143 103.771C139.503 102.753 136.442 100.995 136.442 97.4327C136.442 93.4222 139.7 90.6874 144.674 90.6874C147.979 90.6874 150.435 92.0174 152.267 93.4153L149.41 97.2494C147.877 96.1365 146.364 95.4104 144.871 95.4104C143.222 95.4104 142.407 96.0551 142.407 97.134C142.407 98.5048 144.423 99.0341 146.655 99.8484C149.383 100.86 152.457 102.393 152.457 106.329C152.457 110.265 149.383 113.278 143.608 113.278C140.765 113.278 137.521 112.05 135.336 110.272H135.343Z"
                  fill="white"
                />
                <path
                  d="M155.233 101.993C155.233 94.8269 160.275 90.6942 165.724 90.6942C171.173 90.6942 176.222 94.8337 176.222 101.993C176.222 109.152 171.18 113.278 165.724 113.278C160.268 113.278 155.233 109.138 155.233 101.993ZM169.687 101.993C169.687 98.2674 168.377 95.8447 165.731 95.8447C163.084 95.8447 161.781 98.2674 161.781 101.993C161.781 105.718 163.091 108.127 165.731 108.127C168.37 108.127 169.687 105.725 169.687 101.993Z"
                  fill="white"
                />
                <path
                  d="M178.468 110.272L181.338 106.295C183.293 107.774 185.091 108.555 186.896 108.555C188.796 108.555 189.617 107.829 189.617 106.702C189.617 105.284 187.48 104.639 185.267 103.771C182.628 102.753 179.567 100.995 179.567 97.4327C179.567 93.4222 182.824 90.6874 187.799 90.6874C191.103 90.6874 193.56 92.0174 195.392 93.4153L192.535 97.2494C191.002 96.1365 189.488 95.4104 187.995 95.4104C186.346 95.4104 185.532 96.0551 185.532 97.134C185.532 98.5048 187.548 99.0341 189.78 99.8484C192.508 100.86 195.582 102.393 195.582 106.329C195.582 110.265 192.508 113.278 186.733 113.278C183.89 113.278 180.646 112.05 178.461 110.272H178.468Z"
                  fill="white"
                />
                <path
                  d="M139.537 46.2395C139.537 54.3284 133.192 60.8837 125.361 60.8837H99.7917L84.2586 75.8128L78.0766 60.8837H68.7866C60.9557 60.8837 54.6108 54.3284 54.6108 46.2395V19.3604C54.6108 11.2715 60.9557 4.71631 68.7866 4.71631H125.361C133.192 4.71631 139.537 11.2715 139.537 19.3604"
                  stroke="#5FCE1D"
                  strokeWidth="7.46455"
                  strokeMiterlimit="10"
                />
                <path
                  d="M70.3969 42.8845C70.3969 38.3447 74.007 35.8881 82.5301 34.9856C82.4012 33.0991 81.4648 31.8709 79.1236 31.8709C77.3186 31.8709 75.5135 32.6309 73.4234 33.8184L71.1569 29.6247C73.8916 27.9553 76.9724 26.7882 80.3586 26.7882C85.8688 26.7882 88.9157 29.9369 88.9157 36.5396V48.8561H83.7109L83.2494 46.6507H83.0934C81.2815 48.2454 79.2458 49.3786 76.8232 49.3786C72.8941 49.3786 70.4036 46.515 70.4036 42.8845H70.3969ZM82.5301 42.5994V38.8333C77.9903 39.444 76.511 40.7401 76.511 42.3687C76.511 43.7599 77.4678 44.4385 78.9811 44.4385C80.4944 44.4385 81.3969 43.7259 82.5301 42.5926V42.5994Z"
                  fill="white"
                />
                <path
                  d="M94.2766 42.1652V18.4347H100.662V42.423C100.662 43.7938 101.293 44.2281 101.816 44.2281C102.06 44.2281 102.237 44.2281 102.596 44.1399L103.356 48.8494C102.65 49.148 101.605 49.3786 100.153 49.3786C95.7763 49.3786 94.2766 46.5217 94.2766 42.1652Z"
                  fill="white"
                />
                <path
                  d="M105.446 38.0936C105.446 30.9276 110.488 26.795 115.938 26.795C121.387 26.795 126.435 30.9344 126.435 38.0936C126.435 45.2528 121.393 49.3786 115.938 49.3786C110.482 49.3786 105.446 45.2392 105.446 38.0936ZM112.687 16.5414H119.202L123.897 22.2348L121.468 24.5014L116.032 20.5044H115.856L110.421 24.5014L107.991 22.2348L112.687 16.5414ZM119.894 38.0936C119.894 34.3681 118.584 31.9455 115.944 31.9455C113.305 31.9455 111.995 34.3681 111.995 38.0936C111.995 41.8191 113.305 44.2281 115.944 44.2281C118.584 44.2281 119.894 41.8259 119.894 38.0936Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3512_4347">
                  <rect width="196" height="114" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p>Todos os concursos em um só lugar</p>
          </div>
          <SecondSearchBar
            setTermoPesquisado={setTermoPesquisado}
            setSearchReset={setSearchReset}
            page="Homepage"
            width="76rem"
            setSuggestion={setSuggestion}
            setSelectedSuggestion={setSelectedSuggestion}
          />
          <div className="flexSuggestions">
            <Link to={`${selectedSuggestion}/${suggestion[0]}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M16.3063 14.8767L12.327 10.8974C13.4114 9.57114 13.9445 7.87881 13.8162 6.17045C13.6878 4.4621 12.9078 2.86843 11.6373 1.71909C10.3669 0.569754 8.70335 -0.0473246 6.99072 -0.0045034C5.27809 0.0383178 3.64743 0.737763 2.43603 1.94916C1.22464 3.16055 0.525195 4.79121 0.482374 6.50384C0.439553 8.21648 1.05663 9.88005 2.20597 11.1505C3.35531 12.4209 4.94898 13.201 6.65733 13.3293C8.36569 13.4577 10.058 12.9245 11.3843 11.8401L15.3636 15.8194C15.4894 15.9409 15.6578 16.0081 15.8326 16.0065C16.0074 16.005 16.1746 15.9349 16.2982 15.8113C16.4218 15.6877 16.4919 15.5205 16.4934 15.3457C16.4949 15.1709 16.4277 15.0025 16.3063 14.8767ZM7.16829 12.0147C6.11346 12.0147 5.08231 11.702 4.20525 11.1159C3.32819 10.5299 2.6446 9.69693 2.24093 8.72239C1.83727 7.74785 1.73165 6.6755 1.93744 5.64093C2.14322 4.60637 2.65118 3.65606 3.39706 2.91018C4.14294 2.1643 5.09324 1.65635 6.12781 1.45056C7.16237 1.24477 8.23473 1.35039 9.20927 1.75406C10.1838 2.15772 11.0168 2.84131 11.6028 3.71837C12.1888 4.59543 12.5016 5.62658 12.5016 6.68141C12.5 8.09541 11.9376 9.45105 10.9378 10.4509C9.93792 11.4507 8.58229 12.0132 7.16829 12.0147Z"
                  fill="#EDEEFA"
                />
              </svg>
              <p>{suggestion[0]}</p>
            </Link>
            <Link to={`${selectedSuggestion}/${suggestion[1]}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M16.3063 14.8767L12.327 10.8974C13.4114 9.57114 13.9445 7.87881 13.8162 6.17045C13.6878 4.4621 12.9078 2.86843 11.6373 1.71909C10.3669 0.569754 8.70335 -0.0473246 6.99072 -0.0045034C5.27809 0.0383178 3.64743 0.737763 2.43603 1.94916C1.22464 3.16055 0.525195 4.79121 0.482374 6.50384C0.439553 8.21648 1.05663 9.88005 2.20597 11.1505C3.35531 12.4209 4.94898 13.201 6.65733 13.3293C8.36569 13.4577 10.058 12.9245 11.3843 11.8401L15.3636 15.8194C15.4894 15.9409 15.6578 16.0081 15.8326 16.0065C16.0074 16.005 16.1746 15.9349 16.2982 15.8113C16.4218 15.6877 16.4919 15.5205 16.4934 15.3457C16.4949 15.1709 16.4277 15.0025 16.3063 14.8767ZM7.16829 12.0147C6.11346 12.0147 5.08231 11.702 4.20525 11.1159C3.32819 10.5299 2.6446 9.69693 2.24093 8.72239C1.83727 7.74785 1.73165 6.6755 1.93744 5.64093C2.14322 4.60637 2.65118 3.65606 3.39706 2.91018C4.14294 2.1643 5.09324 1.65635 6.12781 1.45056C7.16237 1.24477 8.23473 1.35039 9.20927 1.75406C10.1838 2.15772 11.0168 2.84131 11.6028 3.71837C12.1888 4.59543 12.5016 5.62658 12.5016 6.68141C12.5 8.09541 11.9376 9.45105 10.9378 10.4509C9.93792 11.4507 8.58229 12.0132 7.16829 12.0147Z"
                  fill="#EDEEFA"
                />
              </svg>
              <p>{suggestion[1]}</p>
            </Link>
            <Link to={`${selectedSuggestion}/${suggestion[2]}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M16.3063 14.8767L12.327 10.8974C13.4114 9.57114 13.9445 7.87881 13.8162 6.17045C13.6878 4.4621 12.9078 2.86843 11.6373 1.71909C10.3669 0.569754 8.70335 -0.0473246 6.99072 -0.0045034C5.27809 0.0383178 3.64743 0.737763 2.43603 1.94916C1.22464 3.16055 0.525195 4.79121 0.482374 6.50384C0.439553 8.21648 1.05663 9.88005 2.20597 11.1505C3.35531 12.4209 4.94898 13.201 6.65733 13.3293C8.36569 13.4577 10.058 12.9245 11.3843 11.8401L15.3636 15.8194C15.4894 15.9409 15.6578 16.0081 15.8326 16.0065C16.0074 16.005 16.1746 15.9349 16.2982 15.8113C16.4218 15.6877 16.4919 15.5205 16.4934 15.3457C16.4949 15.1709 16.4277 15.0025 16.3063 14.8767ZM7.16829 12.0147C6.11346 12.0147 5.08231 11.702 4.20525 11.1159C3.32819 10.5299 2.6446 9.69693 2.24093 8.72239C1.83727 7.74785 1.73165 6.6755 1.93744 5.64093C2.14322 4.60637 2.65118 3.65606 3.39706 2.91018C4.14294 2.1643 5.09324 1.65635 6.12781 1.45056C7.16237 1.24477 8.23473 1.35039 9.20927 1.75406C10.1838 2.15772 11.0168 2.84131 11.6028 3.71837C12.1888 4.59543 12.5016 5.62658 12.5016 6.68141C12.5 8.09541 11.9376 9.45105 10.9378 10.4509C9.93792 11.4507 8.58229 12.0132 7.16829 12.0147Z"
                  fill="#EDEEFA"
                />
              </svg>
              <p>{suggestion[2]}</p>
            </Link>
          </div>
        </div>
      </header>
      <div className="divider"></div>
      <section className="lastOpportunities">
        <h2 className="dontLost">Não perca as últimas oportunidades:</h2>
        <div className="slideContainer">
          {renderizacao ? (
            <SlideSwiper
              setRecarregarComTag={setRecarregarComTag}
              recarregarComTag={recarregarComTag}
              renderizacao={renderizacao}
            />
          ) : (
            <div
              style={{
                width: "fit-content",
                margin: "0 auto",
                display: "flex",
                gap: "1.86rem",
              }}
            >
              <Skeleton width={"38.4rem"} height={"36.1406rem"} />
              <Skeleton width={"38.4rem"} height={"36.1406rem"} />
              <Skeleton width={"38.4rem"} height={"36.1406rem"} />
              <Skeleton width={"38.4rem"} height={"36.1406rem"} />
            </div>
          )}
        </div>
      </section>
      <section className="aboutAlo">
        <div className="flexAbout">
          <h1>Sobre a Alô Concursos</h1>
          <p>
            A Alô Concursos é um site de notícias sobre concursos públicos
            baseado em tecnologias modernas de sistemas de buscas e
            armazenamento de dados. Tem o objetivo de levar ao usuário uma
            experiência digital compatível com as ferramentas mais atuais, de
            forma inovadora nesse setor, por meio de buscas segmentadas, filtros
            e customização de alertas. <Link to={"/sobre"}>Saiba mais</Link>.
          </p>
        </div>
      </section>
      <section
        style={{ backgroundImage: `url(${features_background})` }}
        className="benefits"
      >
        <h1>Benefícios</h1>
        <div className="flexAboutCards">
          <AboutCards
            image={checkCirclee}
            tittle="Facilidade para encontrar o que você procura"
            subTittle={
              "A Alô concursos usa uma moderna tecnologia de mecanismo de busca para garantir que você encontre de forma rápida e prática a sua vaga no serviço público."
            }
          />
          <AboutCards
            image={timeFast}
            tittle="Rapidez na atualização dos concursos"
            subTittle={
              "Nossa equipe trabalha diariamente, com auxílio da tecnologia, para garantir que o site esteja sempre atualizado com os últimos concursos abertos no Brasil."
            }
          />
          <AboutCards
            image={add}
            tittle="Ampla cobertura dos concursos disponíveis"
            subTittle={
              "Nosso objetivo é ser o site com a maior cobertura de concursos do Brasil. Não importa o que você procura e onde você deseja trabalhar. Se o concurso é público, ele será publicado na Alô Concursos!"
            }
          />
        </div>
      </section>
      <section
        style={{ backgroundImage: `url(${HowWorks})` }}
        className="tutorial"
      >
        <div className="flexSecond">
          <h2>Como funciona?</h2>
          <div className="flexTutorial">
            <TutorialBox
              passo={1}
              image={passo1}
              alt="Busca de concursos, imagem da pesquisa"
              tittle="Busque por concursos ou cargos"
              subtittle="Selecione a opção “por concurso” ou “por cargos” na barra de navegação principal.

A busca padrão é por “cargos” para o caso de não selecionar nenhuma das opções."
            />
            <TutorialBox
              passo={2}
              image={passo2}
              alt="Busca de concursos, imagem da pesquisa"
              tittle="Filtre sua busca"
              subtittle="Utilize os filtros disponíveis para refinar sua pesquisa de acordo com  suas preferências.

Você pode filtrar suas buscas pelo órgão responsável pelo concurso, a  localização geográfica desejada, o nível de escolaridade, a faixa  salarial, a abrangência do concurso, o tipo de contrato e outros."
            />
            <TutorialBox
              passo={3}
              image={passo3}
              alt="Busca de concursos, imagem da pesquisa"
              tittle="Informações detalhadas do concurso"
              subtittle="Para cada concurso você vai encontrar todos os documentos pertinentes, como editais e retificações, na página do concurso."
            />
          </div>
        </div>
        <p className="simple">Simples assim!</p>
      </section>
      <section
        className="statistcs"
        style={{ backgroundImage: `url(${newStatistics})` }}
      >
        <div className="flexStatistcs">
          <HomepageStatistics
            tittle={aloData && aloData.contest_count}
            subttittle={"Concursos registrados"}
            text={"Número total de concursos registrados"}
          />
          <HomepageStatistics
            tittle={
              aloData && aloData.total_vacancies
                ? aloData.total_vacancies === null
                  ? 0
                  : formatMilliar(aloData.total_vacancies)
                : 0
            }
            subttittle={"Vagas abertas"}
            text={"Número total de vagas abertas agora!"}
          />
          <HomepageStatistics
            tittle={aloData && convertKMoney(aloData.max_wage)}
            subttittle={"Maior salário"}
            text={"Maior salário dos concursos registrados"}
            rs={true}
          />
          <HomepageStatistics
            tittle={aloData && conversaoDia(aloData.last_update)}
            subttittle={"Último concurso"}
            text={"Data que o último concurso foi adicionado"}
          />
        </div>
        <div className="flexStatistcsMobile">
          <HomepageStatistics
            tittle={aloData && convertKMoney(aloData.max_wage)}
            subttittle={"Maior salário"}
            text={"Maior salário dos concursos registrados"}
            rs={true}
          />
          <HomepageStatistics
            tittle={aloData && conversaoDia(aloData.last_update)}
            subttittle={"Último concurso"}
            text={"Data que o último concurso foi adicionado"}
          />
        </div>
        <div className="flexStatistcsMobile">
          <HomepageStatistics
            tittle={aloData && convertKMoney(aloData.max_wage)}
            subttittle={"Maior salário"}
            text={"Maior salário dos concursos registrados"}
            rs={true}
          />
          <HomepageStatistics
            tittle={aloData && conversaoDia(aloData.last_update)}
            subttittle={"Último concurso"}
            text={"Data que o último concurso foi adicionado"}
          />
        </div>
      </section>
      <section
        style={{ backgroundImage: `url(${CTA_background})` }}
        className="oportunit"
      >
        <div className="boxCTA">
          <div className="flexCTA">
            <h2>Não perca nenhuma oportunidade!</h2>
            <p>
              Assine agora e tenha acesso exclusivo a buscas ilimitadas; busca
              por cargos e concursos; acesso a todos os filtros; informações
              completas sobre os concursos; e 25% de desconto.
            </p>
          </div>
          <Link to="/planos">Conhecer planos</Link>
        </div>
      </section>
    </StyledHomepage>
  );
}

export default Homepage;
