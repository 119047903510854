import React from "react";
import { StyledBlogCard } from "../styles/BlogCard";
import { useNavigate } from "react-router-dom";
import { formatarDataBlog } from "../tools/functions";

function BlogCard({ images, title, last_modified, slug }) {
  const Navigate = useNavigate();
  return (
    <StyledBlogCard onClick={() => Navigate(`/noticias/${slug}`)}>
      <img src={images[0].url} alt={images[0].caption} />
      <h3>{title}</h3>
      <p>{formatarDataBlog(last_modified)}</p>
    </StyledBlogCard>
  );
}

export default BlogCard;
